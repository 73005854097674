import React, { Component } from 'react';
import { Fade } from 'react-awesome-reveal';
import Typist from 'react-typist';
import {
  faGithub,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import styles from '../styles/intro.module.scss';
import YAMLData from '../../content/My-YAML-Content.yaml';

function Links(props) {
  return (
    <div>
      <div
        id={styles.circle}
        style={props.show ? { display: 'block' } : { display: 'none' }}
      />
      <Fade delay={4300} duration={300} triggerOnce>
        <div
          className={styles.introP}
          style={props.show ? { display: 'block' } : { display: 'none' }}
        >

          <p
            dangerouslySetInnerHTML={{ __html: YAMLData.hello_p }}
          />
          <p className={styles.divided}>
            <span className={styles.seeMore}>See more</span>
            <span className={styles.centerLine} />
            <span className={styles.links}>
              <a href="https://github.com/fc-anjos/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faGithub} className={styles.icon} />
              </a>
              <a href="https://www.linkedin.com/in/fc-anjos/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />
              </a>
            </span>
          </p>
        </div>
      </Fade>
    </div>
  );
}

export default class Intro extends Component {
  state = {
    show_name: false,
    show_p: false,
  }

  showName = () => {
    this.setState({
      show_name: true,
    });
  }

  showP = () => {
    setTimeout(() => {
      this.setState({
        show_p: true,
      });
    }, 200);
  }

  render() {
    return (
      <Row id="hello">
        <Col xs="1" sm="2" />
        <div className={`col-10 col-sm-6 col-lg-5 ${styles.firstScreen}`}>
          <div className={styles.presentation}>
            <div className="sectionIndexContainer">
              <span className="sectionIndex">01</span>
            </div>

            <Typist
              cursor={{
                show: false,
              }}
              onTypingDone={this.showName}
              avgTypingDelay={60}
            >
              <span className="subTitle">Hello, I'm</span>
            </Typist>
            <h1
              style={this.state.show_name ? { display: 'block' } : { display: 'none' }}
              className={styles.sectionTitle}
            >
              <Typist
                cursor={{
                  blink: true,
                  hideWhenDone: true,
                }}
                avgTypingDelay={140}
                startDelay={1000}
                onTypingDone={this.showP}
              >
                <span className={styles.name}>Felipe</span>
                <br />
                <span className={styles.name}>dos Anjos</span>
              </Typist>
            </h1>
            <Links show={this.state.show_p} />
          </div>
        </div>
      </Row>
    );
  }
}
